import React, { useContext, useEffect } from "react";
import { AppContext } from "../../Context/AppContext/AppContext";
import LineCard from "../../Components/LineCard/LineCard";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import Container from "../../Components/Container/Container";

const LinesPage = () => {
  const { busLines, getBusLines } = useContext(AppContext);

  useEffect(() => {
    getBusLines();
  }, [getBusLines]);

  if (!busLines || busLines.length < 1) {
    return <LoadingPage />;
  }

  // Sorting the bus lines numerically
  const sortedLines = [...busLines].sort((a, b) => Number(a.line) - Number(b.line));

  // Logic to decide which bus (6 or 6T) to show based on activity
  const filteredLines = sortedLines.filter((line) => {
    // Get buses with id 6 and 6T
    const bus6 = line.id === "6";
    const bus6T = line.id === "6T";

    // If the bus is 6T, we only want it to show if it is active (nextStop?.name exists)
    if (bus6T) {
      return line.nextStop?.name ? true : false; // Show 6T if it's active
    }

    // If the bus is 6, we only want it to show if 6T is not active (or if 6T doesn't exist)
    if (bus6) {
      const bus6TActive = sortedLines.some((bus) => bus.id === "6T" && bus.nextStop?.name);
      return bus6TActive ? false : true; // Hide 6 if 6T is active
    }

    // For all other buses, show them by default
    return true;
  });

  return (
    <div style={{ marginTop: "70px", textAlign: "center" }}>
      <h2 style={{ marginBottom: "0", marginTop: "10px" }}>Линии</h2>
      <Container>
        {filteredLines.length > 0 ? (
          filteredLines.map((line, index) => (
            <LineCard key={`${line.id}-${index}`} {...line} />
          ))
        ) : (
          <p>No active buses available.</p>
        )}
      </Container>
    </div>
  );
};

export default LinesPage;
