import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext/AppContext";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import Container from "../../Components/Container/Container";
import Timeline from "../../Components/Timeline/Timeline";
import "../../Styles/BusLineDetailsStyles/BusLineDetails.css";
import OperatingHoursModal from "../../Components/OperatingHoursModal/OperatingHoursModal";
import { scrollToTop } from "../../Utils/UtilityFunctions";
import BusRouteMap from "../../Components/MapComponent/MapComponent";

const BusLineDetails: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    fetchBusLineDetailsById,
    busLineDetails,
    resetBusLineDetails,
    fetchingOperatingHoursByLineID,
    operatingHoursForEachLine,
    resetOperatinghouts,
    openOperatinghoursModal,
    operatinhHoursModal,
  } = useContext(AppContext);

  const [geoJsonData, setGeoJsonData] = useState(null);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    const validBusLineIds = ["1", "1T", "4", "4T", "5", "6", "6T", "14", "15", "10"];
    if (id) {
      if (!validBusLineIds.includes(id)) {
        navigate("/error");
        return;
      }
    }

    if (id) {
      fetchBusLineDetailsById(id);
      fetchingOperatingHoursByLineID(id);
      scrollToTop();
      interval = setInterval(() => {
        fetchBusLineDetailsById(id);
        fetchingOperatingHoursByLineID(id);
      }, 60000);
    }

    const fetchGeoJson = async () => {
      try {
        const response = await fetch(
          `https://getroute-hx67ea5zua-ew.a.run.app/getRoute?busId=${id}`
        );

        if (!response.ok) {
          if (response.status === 404) {
            console.warn(`GeoJSON file not found for bus ID: ${id}`);
            setGeoJsonData(null);
            return;
          }
          throw new Error(`Failed to fetch GeoJSON: ${response.statusText}`);
        }

        const data = await response.json();
        setGeoJsonData(data);
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
        setGeoJsonData(null);
      }
    };

    fetchGeoJson();

    return () => {
      resetBusLineDetails();
      resetOperatinghouts();
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [id]);

  if (!busLineDetails || !operatingHoursForEachLine) {
    return <LoadingPage />;
  }

  return (
    <div style={{ marginTop: "60px" }}>
      <div className="titleLineDetails">
        <h2 style={{ marginBottom: "0", marginTop: "10px" }}>
          Линија {busLineDetails.line}{" "}
          {busLineDetails.id &&
            (busLineDetails.id.includes("1T") || busLineDetails.id.includes("4T")) && " - Транскоп"}
          {busLineDetails.id && busLineDetails.id.includes("14") && "(7)"}
          {busLineDetails.id && busLineDetails.id.includes("10") && "(11)"}
        </h2>

        <button
          className="calendarButton"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            openOperatinghoursModal();
          }}
        >
          <img
            className="calendarImage"
            src="/images/img/calendarDark.png"
            alt=""
          />
        </button>

        <p style={{ marginBottom: "10px", marginTop: "0", fontSize: "14px" }}>
          {busLineDetails.description
            .toLowerCase()
            .split(/([\s\-–]+)/)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join("")}
        </p>

        <BusRouteMap
          busId={id as string}
          stops={busLineDetails.stops.map((stop: any) => ({
            id: stop.id,
            name: stop.name,
            location: stop.location,
          }))}
          geoJsonData={
            geoJsonData || { type: "FeatureCollection", features: [] }
          }
        />
      </div>

      <Container>
        <Timeline
          data={busLineDetails.stops}
          nextStop={busLineDetails.nextStop}
          timeToNextStop={busLineDetails.timeToNextStop}
          id={busLineDetails.id}
        />
        {operatinhHoursModal && (
          <OperatingHoursModal
            id={busLineDetails.id}
            lineName={busLineDetails.line}
            monthlyOverrides={operatingHoursForEachLine.monthly_overrides}
            nationalHolidays={
              operatingHoursForEachLine.holidays[0].national_holidays
            }
            religiousHolidays={
              operatingHoursForEachLine.holidays[1]?.religious_holidays
            }
          />
        )}
      </Container>
    </div>
  );
};

export default BusLineDetails;
