import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "../Container/Container";
import "../../Styles/FooterStyles/FooterAnimationStyle.css";
import { COLORS } from "../../Styles/GlobalStyles";

const Footer = () => {
  const navigate = useNavigate();

  const navigateToComingSoon = () => {
    navigate("/coming-soon");
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.cardBackgroundColorLight,
        margin: "30px 0px",
      }}
    >

    </div>
  );
};

export default Footer;
