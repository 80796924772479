import React, { useContext, useEffect } from "react";
import "../../Styles/OperatinghoursModalStyle/OperatinghoursModalStyle.css";
import { AppContext } from "../../Context/AppContext/AppContext";
import { BORDERRADIUS, COLORS, MARGINS } from "../../Styles/GlobalStyles";
import { MonthlyOverrides, NationalHolidays } from "../../Types/Types";
import {
  formatDateToMacedonian,
  formatDaysAndTimes,
  translateMonthToMacedonian,
} from "../../Utils/UtilityFunctions";

interface Props {
  id: string;
  lineName: string;
  monthlyOverrides: MonthlyOverrides[];
  nationalHolidays: NationalHolidays[];
  religiousHolidays?: NationalHolidays[];
}

const OperatingHoursModal: React.FC<Props> = ({
  id,
  lineName,
  monthlyOverrides,
  nationalHolidays,
  religiousHolidays,
}) => {
  const { closeOperatinghoursModal } = useContext(AppContext);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if ((event.target as HTMLElement).classList.contains("modal-overlay")) {
      closeOperatinghoursModal();
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        handleOverlayClick(event);
      }}
    >
      <div>
        <div
          className="modal-content"
          style={{
            backgroundColor: COLORS.mossGreen,
            borderRadius: BORDERRADIUS.borderRadius15,
           }}
        >
          <button
            className="close-button"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              closeOperatinghoursModal();
            }}
          >
            <img src="/images/img/x-button.png" alt="" />
          </button>
          <div className="modal-body">
            <h4
              style={{
                color: "white",
                margin: MARGINS.margin15,
                marginTop: 30,
              }}
            >
              Периодични отстапувања и неработни денови за Линија {lineName}
            </h4>
            {monthlyOverrides && (
              <>
                <h5 style={{ color: "white" }}>Периодични отстапувања:</h5>
                {monthlyOverrides.map((override, index) => {
                  return (
                    <div
                      key={`${override.end_date}-${index}`}
                      className="override-custom-container"
                      style={{
                        backgroundColor: "white",
                        borderRadius: BORDERRADIUS.borderRadius15,
                      }}
                    >
                      <div>
                        <p className="font-weight-bold">
                          {formatDateToMacedonian(override.start_date)} -{" "}
                          {formatDateToMacedonian(override.end_date)}
                        </p>
                        <p>{formatDaysAndTimes(override.days)}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {nationalHolidays && (
              <>
                <h5 style={{ color: "white" }}>Неработни денови:</h5>
                {nationalHolidays.map((holiday, index) => (
                  <div
                    key={`${holiday.day}-${index}`}
                    className="override-custom-container"
                    style={{
                      backgroundColor: "white",
                      borderRadius: BORDERRADIUS.borderRadius15,
                    }}
                  >
                    <p className="font-weight-bold">
                      {holiday.day} {translateMonthToMacedonian(holiday.month)}{" "}
                      - {holiday.description}
                    </p>
                    <p>
                      од: {holiday.start} - {holiday.end}
                    </p>
                  </div>
                ))}
              </>
            )}

            {religiousHolidays && (
              <>
                <h5 style={{ color: "white" }}>Верски празници:</h5>
                {religiousHolidays.map((item, index) => (
                  <div
                    key={`${item.day}-${index}`}
                    className="override-custom-container"
                    style={{ backgroundColor: "white" }}
                  >
                    <p className="font-weight-bold">
                      {item.day} {translateMonthToMacedonian(item.month)} -{" "}
                      {item.description}
                    </p>
                    <p>
                      од: {item.start} - {item.end}
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatingHoursModal;
