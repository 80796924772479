import React from "react";
import { BusLines } from "../../Types/Types";
import "../../Styles/LineCardStyle/LineCardStyle.css";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utils/UtilityFunctions";
import { COLORS } from "../../Styles/GlobalStyles";

const LineCard: React.FC<BusLines> = ({ id, line, description, nextStop }) => {
  const navigate = useNavigate();

  const navigateTo = (id: string) => {
    navigate(`/line/${id}`);
    scrollToTop();
  };

  return (
    <div
      className="busLineDetailsContainer"
      style={{ borderColor: COLORS.mossGreen, cursor: "pointer" }}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        navigateTo(id);
      }}
    >
      <div className="mr-2">
        <img
          className="imgBusLineCard"
          src="./images/img/busLinesAndBusStopDetailsLine.png"
          alt={`Bus line ${line}`}
        />
      </div>

      <div>
        <div className="d-flex align-items-center">
          <h4 className="lineTitle m-0">
          Линија {line} { (id.includes("1T") || id.includes("4T")) && " - Транскоп" }
            {id.includes("14") && "(7)"}
            {id.includes("10") && "(11)"}
            {nextStop?.name && (
              <img
                src="./images/img/voZivo.png"
                alt=""
                className="blinkingImageLinePage"
              />
            )}
          </h4>
        </div>
        {description && (
          <p className="lineDescription">
            {description
              .toLowerCase()
              .split(/([\s\-–]+)/)
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join("")}
          </p>
        )}
      </div>
    </div>
  );
};

export default LineCard;