import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../Context/AppContext/AppContext";
import LoadingPage from "../../Components/LoadingPage/LoadingPage";
import "../../Styles/BusStopDetailsStyle/BusStopDetailsStyle.css";
import { COLORS, MARGINS } from "../../Styles/GlobalStyles";
import Container from "../../Components/Container/Container";
import { findClosestTimes, formatTime, scrollToTop } from "../../Utils/UtilityFunctions";
import FullTimeScheduledModal from "../../Components/FullTimeScheduledModal/FullTimeScheduledModal";
import type { BusStopDetails as BusStopDetailsType, ActiveBus, ScheduledBusStopDetails, BusLines } from "../../Types/Types";

const BusStopDetails: React.FC = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const {
    getBusStopDetailsById,
    busStopDetails,
    resetBusStopDetails,
    fullScheduledTimeModal,
    openScheduledTimeModal,
    busLines,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (id) {
      getBusStopDetailsById(id);
      scrollToTop();
      interval = setInterval(() => {
        getBusStopDetailsById(id);
      }, 60000);
    }

    return () => {
      resetBusStopDetails();
      if (interval) {
        clearInterval(interval);
      }
    };

    //TODO check if resetBusstopDetails needs to be here and if getbusstopdetailsbyid needs to be in the dependency array
  }, [id, getBusStopDetailsById]);

  const handleToggleCollapse = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const calculateTimeDifference = (scheduledTime: string): number => {
    const currentTime = new Date();
    const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();

    const [hours, minutes] = scheduledTime.split(":").map((time) => parseInt(time, 10));
    const scheduledMinutes = hours * 60 + minutes;

    let timeDifference = scheduledMinutes - currentMinutes;

    if (timeDifference < 0) {
      timeDifference += 1440;
    }

    return timeDifference;
  };

  if (!Array.isArray(busStopDetails) || busStopDetails.length === 0) {
    return <LoadingPage />;
  }

  const busStop: BusStopDetailsType = busStopDetails[0];

  const navigateTo = (id: string) => {
    navigate(`/line/${id}`);
    scrollToTop();
  };

  const sortedBusStops = busStop.scheduled
  .map((item: ScheduledBusStopDetails) => {
    const activeBus = busStop.activeBuses.find((bus: ActiveBus) => bus.line === item.id);

    
    // Get next times from findClosestTimes
    const { nextTimes } = findClosestTimes(
      new Date().getHours() * 60 + new Date().getMinutes(),
      item.scheduledTime
    );

    

    let activeTime: string[] | null = null;
    let updatedNextTimes = [...nextTimes];

    // Filter out past times
    const currentTimeInMinutes = new Date().getHours() * 60 + new Date().getMinutes();
    updatedNextTimes = updatedNextTimes.filter((time) => {
      const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
      const timeInMinutes = hours * 60 + minutes;
      return timeInMinutes > currentTimeInMinutes;
    });

    // If no future times, show the first times of the next morning
    if (updatedNextTimes.length === 0) {
      updatedNextTimes = item.scheduledTime.slice(0, 4);
    }

    if (activeBus) {
      // Format totalTime (in seconds) to a readable time string
      activeTime = [formatTime(activeBus.totalTime)];
      // Remove the first scheduled time if active time is available
      updatedNextTimes = updatedNextTimes.slice(1);
    }

    return { ...item, activeTime, nextTimes: updatedNextTimes, totalTime: activeBus?.totalTime };
  })
  .sort((a, b) => {
    // If both have activeTime (totalTime), sort by the smallest totalTime
    if (a.totalTime !== undefined && b.totalTime !== undefined) {
      return a.totalTime - b.totalTime; // Sort by closest totalTime (in seconds)
    }

    // If only one has activeTime, prioritize it
    if (a.totalTime !== undefined) return -1;
    if (b.totalTime !== undefined) return 1;

    // If neither has activeTime, sort by the closest scheduled time
    const aTimeDifference = calculateTimeDifference(a.nextTimes[0]);
    const bTimeDifference = calculateTimeDifference(b.nextTimes[0]);
    return aTimeDifference - bTimeDifference; // Sort by closest scheduled time
  });


  return (
    <div>
      <div className="titleContent" style={{ marginTop: "60px" }}>
        <div className="titleContentInner">
          <h2 style={{ marginBottom: "0", marginTop: "10px" }}>
            {busStop.stopName} <span className="spanId">({busStop.id})</span>
          </h2>
          <p style={{ marginBottom: "10px", marginTop: "0", fontSize: "14px" }}>
            {busStop.direction}
          </p>
        </div>
        {busStop.scheduled.length > 0 && (
          <div className="d-flex">
            {busStop.scheduled.map((item: ScheduledBusStopDetails, index: number) => (
              <p
                className="titleLineNumbers"
                key={`${item.id}-${index}`}
                style={{ backgroundColor: COLORS.mossGreen, cursor: "pointer" }}
                onClick={(event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
                  event.preventDefault();
                  navigateTo(item.id);
                  scrollToTop();
                }}
              >
                {item.line}
              </p>
            ))}
          </div>
        )}
        {busStop.scheduled.length === 0 && <p>Нема Линии на оваа постојка.</p>}
      </div>

      <Container>
        {sortedBusStops.map((item, index) => {
          const sameBusStop = busLines.find((busLine: BusLines) => busLine.id === item.id);

          return (
            <div
              className="busStopDetailsCard"
              style={{ borderColor: COLORS.mossGreen }}
              key={`${item.id}-${index}`}
              onClick={(
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                event.preventDefault();
                navigateTo(item.id);
                scrollToTop();
              }}
            >
              <div className="busStopDetailsCardInnerContainer">
                <div className="leftSide">
                  <img
                    className="img"
                    src="/images/img/busLinesAndBusStopDetailsLine.png"
                    style={{ marginRight: MARGINS.margin10 }}
                    alt=""
                  />
                  <div>
                    <h4 className="lineName">
                      Линија: {item.line}{" "}
                      {(item.id.includes("1T") || item.id.includes("4T")) &&
                        "- Транскоп"}{" "}
                      {item.id.includes("14") && "(7)"}{" "}
                      {item.id.includes("10") && "(11)"}{" "}
                      {busStop.activeBuses.some(
                        (bus: ActiveBus) => bus.line === item.id
                      ) && (
                        <img
                          src="/images/img/voZivo.png"
                          alt="Active"
                          className="blinkingImage"
                        />
                      )}
                    </h4>
                    {sameBusStop && (
                      <p
                        className="mb-1"
                        style={{
                          color: "grey",
                          textAlign: "left",
                          fontSize: "15px",
                          width: "100%",
                        }}
                      >
                        {sameBusStop.desc}
                      </p>
                    )}

                    <div className="timeContainer">
                      {item.nextTimes.length > 0 && (
                        <div className="scheduledNumbers">
                          {item.activeTime && (
                            <>
                              {item.activeTime.map(
                                (time: string, index: number) => (
                                  <p
                                    key={`${time}-${index}`}
                                    className="activeBusContainer"
                                    style={{
                                      backgroundColor: COLORS.mossGreen,
                                    }}
                                  >
                                    {time}
                                  </p>
                                )
                              )}
                            </>
                          )}

                          {!item.activeTime && (
                            <>
                              {item.nextTimes
                                .slice(0, 4)
                                .map((time: string, index: number) => {
                                  return (
                                    <p
                                      key={`${time}-${index}`}
                                      className="inactiveNumbers"
                                      style={{
                                        backgroundColor:
                                          COLORS.timeBoxColorLight,
                                      }}
                                    >
                                      {time}
                                    </p>
                                  );
                                })}
                            </>
                          )}

                          {item.activeTime &&
                            item.nextTimes
                              .slice(0, 3)
                              .map((time: string, index: number) => {
                                return (
                                  <p
                                    key={`${time}-${index}`}
                                    className="inactiveNumbers"
                                    style={{
                                      backgroundColor: COLORS.timeBoxColorLight,
                                    }}
                                  >
                                    {time}
                                  </p>
                                );
                              })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {item.nextTimes.length > 0 && (
                  <div
                    onClick={(
                      event: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      event.stopPropagation();
                      handleToggleCollapse(index);
                    }}
                  >
                    <small
                      className={`arrow ${
                        expandedIndex === index ? "up" : "down"
                      }`}
                    >
                      {expandedIndex === index ? "↑" : "↑"}
                    </small>
                  </div>
                )}
              </div>
              {expandedIndex === index && item.nextTimes.length > 0 && (
                <div className="collapseSection">
                  <div className="collapseSectionInner">
                    {item.activeTime ? (
                      <>
                        {item.nextTimes
                          .slice(3)
                          .map((time: string, index: number) => (
                            <p
                              key={`${time + 1}-${index}`}
                              className="inactiveNumbersCollapse"
                              style={{
                                backgroundColor: COLORS.timeBoxColorLight,
                              }}
                            >
                              {time}
                            </p>
                          ))}
                      </>
                    ) : (
                      <>
                        {item.nextTimes
                          .slice(4)
                          .map((time: string, index: number) => (
                            <p
                              key={`${time + 1}-${index}`}
                              className="inactiveNumbersCollapse"
                              style={{
                                backgroundColor: COLORS.timeBoxColorLight,
                              }}
                            >
                              {time}
                            </p>
                          ))}
                      </>
                    )}
                  </div>

                  <button
                    className="customButton"
                    style={{ color: COLORS.mossGreen }}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.stopPropagation();
                      openScheduledTimeModal();
                    }}
                  >
                    Целосен возен ред за Линија {item.line}
                  </button>
                  {fullScheduledTimeModal && (
                    <FullTimeScheduledModal
                      id={item.id}
                      lineName={item.line}
                      arrayOfTimes={item.scheduledTime}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </Container>
    </div>
  );
};

export default BusStopDetails;