import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";
import { FeatureCollection } from "geojson";
import "../../Styles/MapComponentStyle/MapComponentStyle.css";

interface BusStop {
  id: string;
  name: string;
  location: [number, number];
}

const BusRouteMap: React.FC<{
  stops: any[];
  geoJsonData?: FeatureCollection;
  busId: string;
}> = React.memo(({ stops, geoJsonData, busId }) => {
  const BITOLA_CENTER = { lat: 41.0297, lng: 21.3292 };
  const [center, setCenter] = useState(BITOLA_CENTER);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [routeCoordinates, setRouteCoordinates] = useState<
    { lat: number; lng: number }[]
  >([]);
  const [selectedStop, setSelectedStop] = useState<BusStop | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = React.useCallback((map: google.maps.Map) => {
    mapRef.current = map;
    setMap(map);
    
    if (stops.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      stops.forEach((stop) => {
        if (stop.location && stop.location.length === 2) {
          bounds.extend(new google.maps.LatLng(stop.location[0], stop.location[1]));
        }
      });
      map.fitBounds(bounds);

      const padding = { top: 50, right: 50, bottom: 50, left: 50 };
      map.fitBounds(bounds, padding);
    }
  }, [stops]);

  const onUnmount = React.useCallback(() => {
    setMap(null);
    mapRef.current = null;
  }, []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  const handleMarkerClick = (stop: BusStop) => {
    const newCenter = {
      lat: stop.location[0],
      lng: stop.location[1],
    };
    setCenter(newCenter);
    setSelectedStop(stop);
    
    if (mapRef.current) {
      mapRef.current.panTo(newCenter);
      mapRef.current.setZoom(15);
    }
  };
  
  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    setSelectedStop(null);
  };

  useEffect(() => {
    if (!geoJsonData || !geoJsonData.features) {
      console.warn("GeoJSON data is missing or invalid.");
      return;
    }

    try {
      const coordinates = geoJsonData.features
        .filter((feature) => feature.geometry.type === "LineString")
        .flatMap((feature: any) => feature.geometry.coordinates)
        .map((coords: any) => ({ lat: coords[1], lng: coords[0] }));

      setRouteCoordinates(coordinates);
    } catch (error) {
      console.error("Error processing GeoJSON data:", error);
    }
  }, [geoJsonData]);

  useEffect(() => {
    if (stops.length === 0) {
      console.warn("No bus stops available.");
    }

    stops.forEach((stop) => {
      if (!stop.location || stop.location.length !== 2) {
        console.warn(`Invalid location data for stop ID: ${stop.id}`);
      }
    });
  }, [stops]);

  useEffect(() => {
    if (mapRef.current && stops.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      stops.forEach((stop) => {
        if (stop.location && stop.location.length === 2) {
          bounds.extend(
            new google.maps.LatLng(stop.location[0], stop.location[1])
          );
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [stops, routeCoordinates]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const stopIcon = {
    path: "M 0,0 m -5,0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
    fillColor: "#0F9565",
    fillOpacity: 1,
    strokeColor: "white",
    strokeWeight: 2,
    scale: 1.5,
  };

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "400px" }}
      center={center}
      zoom={13}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        gestureHandling: "cooperative",
        zoomControl: true,
        clickableIcons: false,
        maxZoom: 18,
        minZoom: 11,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={handleMapClick}>

      {stops.length > 0 &&
        stops
          .filter((stop) => stop.location && stop.location.length === 2)
          .map((stop) => (
            <Marker
              key={stop.id}
              position={{
                lat: stop.location[0],
                lng: stop.location[1],
              }}
              icon={stopIcon}
              onClick={() => handleMarkerClick(stop)}
            />
          ))}

      {selectedStop && (
        <InfoWindow
          position={{
            lat: selectedStop.location[0],
            lng: selectedStop.location[1],
          }}
          options={{
            disableAutoPan: true,
          }}
        >
          <div>
            <h6>{selectedStop.name}</h6>
          </div>
        </InfoWindow>
      )}

      {routeCoordinates.length > 0 && (
        <Polyline
          path={routeCoordinates}
          options={{ strokeColor: "#0F9565", strokeWeight: 4 }}
        />
      )}
    </GoogleMap>
  );
});

export default BusRouteMap;