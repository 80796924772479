import { useContext, useEffect, useState, useRef } from "react";
import Container from "../../Components/Container/Container";
import { AppContext } from "../../Context/AppContext/AppContext";
import ActiveLineCard from "../../Components/ActiveLineCard/ActiveLineCard";
import { useNavigate } from "react-router-dom";
import "../../Styles/ActiveBusLinesPageStyles/ActiveBusLinesPageStyles.css";
import { COLORS } from "../../Styles/GlobalStyles";

const ActiveBusLines = () => {
  const { activeBusHP, fetchActiveBuses, isNotFound } = useContext(AppContext);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const fetchRef = useRef(fetchActiveBuses);

  useEffect(() => {
    fetchRef.current = fetchActiveBuses;
  }, [fetchActiveBuses]);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    let isMounted = true;
    let intervalId : NodeJS.Timeout;

    const initializeDataFetch = async () => {
      try {
        setShowLoader(true);
        await fetchRef.current(); 
        intervalId = setInterval(() => fetchRef.current(), 60000); 
      } finally {
        if (isMounted) setShowLoader(false);
      }
    };

    initializeDataFetch();
    window.addEventListener("resize", handleResize);

    return () => {
      isMounted = false;
      window.removeEventListener("resize", handleResize);
      clearInterval(intervalId); 
    };
  }, []);

  const customSort = (a: string, b: string) => {
    const regex = /^(\d+)(\D*)$/;
    const aMatch = a.match(regex);
    const bMatch = b.match(regex);

    if (aMatch && bMatch) {
      const aNum = parseInt(aMatch[1], 10);
      const bNum = parseInt(bMatch[1], 10);

      if (aNum !== bNum) return aNum - bNum;
      return aMatch[2].localeCompare(bMatch[2]);
    }

    return a.localeCompare(b);
  };

  const filteredActiveBusHP = activeBusHP
    .filter(bus => bus.nextStop?.name)
    .sort((a, b) => customSort(a.id, b.id));

  useEffect(() => {
    setDataLoaded(filteredActiveBusHP.length > 0);
  }, [filteredActiveBusHP]);

  return (
    <div style={{ marginTop: "50px", minHeight: `${viewportHeight - 90}px` }}>
      <Container>
        {showLoader ? ( // Show loader only during initial fetch
          <div className="d-flex justify-content-center" style={{ flex: "1" }}>
            <div
              className="spinner"
              style={{
                marginTop: "70px",
                marginBottom: "60px",
                borderTopColor: COLORS.mossGreen,
              }}
            />
          </div>
        ) : dataLoaded ? ( // Show data if available
          <>
            <div className="text-center mb-3">
              <h2>Активни линии во моментов</h2>
            </div>
            {filteredActiveBusHP.map((item) => (
              <ActiveLineCard {...item} key={item.id} />
            ))}
          </>
        ) : ( // Show "No active lines" if data is empty
          <div className="text-center my-5">
            <h2>Нема активни линии во моментов.</h2>
            <p>Погледнете ги сите линии или сите постојки.</p>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <button
              onClick={() => navigate("/lines")}
              className="navigateToLineScreenButton marginBottomButton text-white"
              style={{
                backgroundColor: COLORS.mossGreen,
                fontSize: "16px",
              }}
            >
              Погледнете ги сите линии!
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ActiveBusLines;