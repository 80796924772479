import { ActiveBus } from "../Types/Types";

export const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const formatDistanceFromMToKm = (distance: number): string => {
  if (distance >= 1000) {
    return `${(distance / 1000).toFixed(2)} км`;
  } else {
    return `${distance} метри`;
  }
};

export const formatTime = (time: number): string => {
  if (time >= 60) {
    const minutes = Math.floor(time / 60);
    return `за ${minutes} мин`;
  } else {
    return "за < 1 мин";
  }
};

export const getMinutesFromMidnight = (timeString: string): number => {
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 60 + minutes;
};


export const findClosestTimes = (
  currentMinutes: number,
  scheduledTimes: string[],
  isStopPassed: boolean = false,
  referenceScheduledIndex: number = 0,
  isActive: boolean = false
): { scheduledTime: string | null; scheduledIndex: number; nextTimes: string[] } => {
  if (!scheduledTimes || scheduledTimes.length === 0) {
    return { scheduledTime: null, scheduledIndex: -1, nextTimes: [] };
  }

  let scheduledIndex = referenceScheduledIndex;


  if (isStopPassed && isActive) {
    scheduledIndex++;
  }

  if (scheduledIndex >= scheduledTimes.length) {
    scheduledIndex = scheduledTimes.length - 1;
  }


  const scheduledTime = scheduledTimes[scheduledIndex] || null;
  const nextTimes = scheduledTimes.slice(scheduledIndex + 0);

  return {
    scheduledIndex,
    scheduledTime,
    nextTimes,
  };
};


export const formatDateToMacedonian = (dateString: string): string => {
  const [year, month, day] = dateString.split("-");

  const monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const monthName = monthNames[parseInt(month) - 1];

  const macedonianMonth = translateMonthToMacedonian(monthName);

  return `${parseInt(day)} ${macedonianMonth}`;
};

type DayEntry = {
  day: string;
  start: string;
  end: string;
};

const dayMap: { [key: string]: string } = {
  monday: "ПОН",
  tuesday: "ВТО",
  wednesday: "СРЕ",
  thursday: "ЧЕТ",
  friday: "ПЕТ",
  saturday: "САБ",
  sunday: "НЕД",
};

export const formatDaysAndTimes = (days: DayEntry[]): string => {
  const allDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const uniqueDays = new Set(days.map((entry) => entry.day.toLowerCase()));

  const sortedDays = Array.from(uniqueDays).sort((a, b) => {
    const daysOrder = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    return daysOrder.indexOf(a) - daysOrder.indexOf(b);
  });

  const startDay = sortedDays[0];
  const endDay = sortedDays[sortedDays.length - 1];

  // Check for special cases
  const isAllDays = allDays.every((day) => uniqueDays.has(day));
  const isMonToSat = startDay === "monday" && endDay === "saturday";
  const isMonToFri = startDay === "monday" && endDay === "friday";

  if (isAllDays) {
    return `ПОН - НЕД ${
      days.length > 0 ? `(${days[0].start} - ${days[0].end})` : ""
    }`;
  }

  if (isMonToSat) {
    return `ПОН - САБ ${
      days.length > 0 ? `(${days[0].start} - ${days[0].end})` : ""
    }`;
  }

  if (isMonToFri) {
    return `ПОН - ПЕТ ${
      days.length > 0 ? `(${days[0].start} - ${days[0].end})` : ""
    }`;
  }

  const dayMap: { [key: string]: string } = {
    monday: "ПОН",
    tuesday: "ВТО",
    wednesday: "СРЕ",
    thursday: "ЧЕТ",
    friday: "ПЕТ",
    saturday: "САБ",
    sunday: "НЕД",
  };

  const dayAbbreviations = sortedDays
    .map((day) => dayMap[day])
    .filter((day) => day !== undefined)
    .join(", ");

  const startTime = days.length > 0 ? days[0].start : "00:00";
  const endTime = days.length > 0 ? days[0].end : "00:00";

  return `${dayAbbreviations} -> ${startTime} - ${endTime}`;
};

export const translateMonthToMacedonian = (month: string): string => {
  const normalizedMonth = month.trim().toLowerCase();

  const monthMap: { [key: string]: string } = {
    january: "Јануари",
    february: "Февруари",
    march: "Март",
    april: "Април",
    may: "Мај",
    june: "Јуни",
    july: "Јули",
    august: "Август",
    september: "Септември",
    october: "Октомври",
    november: "Ноември",
    december: "Декември",
  };

  return monthMap[normalizedMonth] || month;
};

export const translateDayToMacedonian = (day: string): string => {
  const normalizedDay = day.trim().toLowerCase();

  const dayMap: { [key: string]: string } = {
    monday: "Понеделник",
    tuesday: "Вторник",
    wednesday: "Среда",
    thursday: "Четврток",
    friday: "Петок",
    saturday: "Сабота",
    sunday: "Недела",
  };

  return dayMap[normalizedDay] || day;
};

export const options = [
  { value: "", name: "Одберете населба" },
  { value: "Бела чешма", name: "Бела чешма" },
  { value: "Бистрица", name: "Бистрица" },
  { value: "Булевар 1-ви Мај", name: "Булевар 1-ви Мај" },
  { value: "Брусничка", name: "Брусничка Населба" },
  { value: "Буковска", name: "Буковска Населба" },
  { value: "Градски парк/ Железничка", name: "Градски парк/ Железничка" },
  { value: "Горно Оризари", name: "Горно Оризари" },
  { value: "Довлеџик", name: "Довлеџик" },
  { value: "Долно Оризари", name: "Долно Оризари" },
  { value: "Дулие", name: "Дулие" },
  { value: "Јени Маале", name: "Јени Маале" },
  { value: "Карпош", name: "Карпош" },
  { value: "Кравари", name: "Кравари" },
  { value: "Лавчанска", name: "Лавчанска Населба" },
  { value: "Нов пазар", name: "Нов пазар" },
  { value: "Нова Битола", name: "Нова Битола" },
  { value: "Новачки пат", name: "Новачки пат" },
  { value: "Партизанска", name: "Партизанска" },
  { value: "Педагошка", name: "Педагошка" },
  { value: "Приградски", name: "Приградски" },
  { value: "Стрелиште", name: "Стрелиште" },
  { value: "Центар", name: "Центар" },
];
