import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, onValue } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyATk5LPj6kvSYWedEbwtwnn61-Ra6O6JHU",
  authDomain: "citybusbitola.firebaseapp.com",
  databaseURL: "https://citybusbitola-default-rtdb.firebaseio.com",
  projectId: "citybusbitola",
  storageBucket: "citybusbitola.appspot.com",
  messagingSenderId: "491976838163",
  appId: "1:491976838163:web:fa75465d2745f71f6b43e6",
  measurementId: "G-JQ3V17FE3C",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { app, analytics, database, ref, onValue };
