import React, { useRef } from "react";
import { stops, TimeToNextStop } from "../../Types/Types";
import {
  findClosestTimes,
  formatTime,
  getMinutesFromMidnight,
  scrollToTop,
} from "../../Utils/UtilityFunctions";
import "../../Styles/TimelineStyle/TimelineStyle.css";
import { COLORS } from "../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";

interface TimelineProps {
  data?: stops[];
  nextStop?: {
    id: string;
    name: string;
  };
  timeToNextStop?: TimeToNextStop[] | null;
  id: string;
}

const Timeline: React.FC<TimelineProps> = ({
  data,
  nextStop,
  timeToNextStop,
}) => {
  const navigate = useNavigate();
  const nextStopRef = useRef<HTMLDivElement | null>(null);

  const handleNavigate = (id: string) => {
    navigate(`/stop/${id}`);
    scrollToTop();
  };

  const calculateTimeDifference = (scheduledTime: string) => {
    const currentTime = new Date();
    const currentMinutes =
      currentTime.getHours() * 60 + currentTime.getMinutes();

    const [hours, minutes] = scheduledTime
      .split(":")
      .map((time) => parseInt(time, 10));
    const scheduledMinutes = hours * 60 + minutes;

    let timeDifference = scheduledMinutes - currentMinutes;

    // Handle cases where the scheduled time is for the next day
    if (timeDifference < 0) {
      timeDifference += 1440; // Add 24 hours in minutes
    }

    return timeDifference;
  };

  // Function to process timeToNextStop and separate passed stops and upcoming stops
  const processTimeToNextStop = (timeToNextStop: TimeToNextStop[]) => {
    if (!timeToNextStop || timeToNextStop.length === 0)
      return { passedStops: [], upcomingStops: [] };

    const nextStop = timeToNextStop.reduce((prev, curr) =>
      curr.totalTime < prev.totalTime ? curr : prev
    );

    const nextStopIndex = timeToNextStop.indexOf(nextStop);
    const passedStops = timeToNextStop.slice(0, nextStopIndex).reverse();
    const upcomingStops = timeToNextStop.slice(nextStopIndex);

    return { passedStops, upcomingStops };
  };

  const { passedStops, upcomingStops } = processTimeToNextStop(timeToNextStop || []);

  const sortedData = data
  ?.map((item, index) => {
    // Find all occurrences of this stop in timeToNextStop, keeping their original order
    const matchingTimes = [...passedStops, ...upcomingStops].filter(
      (timeItem) => timeItem.toStop === item.name
    );

    // If this stop appears multiple times, use its index in `data` to differentiate occurrences
    const instanceIndex = data.filter(d => d.name === item.name).indexOf(item);
    const matchingTimeToNextStop = matchingTimes[instanceIndex];

    const isPassed = passedStops.some((stop) => stop.toStop === item.name);
    const isNextStop = upcomingStops[0]?.toStop === item.name;

    return {
      ...item,
      isPassed,
      isNextStop,
      totalTime: matchingTimeToNextStop?.totalTime || 0, // Ensure correct instance
    };
  })
  .sort((a, b) => a.totalTime - b.totalTime); // Maintain correct order

  // Calculate the reference index for the first stop based on currentMinutes
  const referenceScheduledTimes = data?.[0]?.scheduledTime || [];
  const referenceScheduledIndex = referenceScheduledTimes.findIndex((time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const timeMinutes = hours * 60 + minutes;
    return timeMinutes >= getMinutesFromMidnight(new Date().toTimeString().slice(0, 5));
  });

  // If no future time is found, default to the first time (next day)
  const adjustedReferenceIndex = referenceScheduledIndex === -1 ? 0 : referenceScheduledIndex;
  const currentMinutes = getMinutesFromMidnight(new Date().toTimeString().slice(0, 5));
  return (
    <div className="timeline-container">
      {sortedData?.map((item, index) => {
        const lastItem = index === sortedData.length - 1;
        const isNextStop = item.name === timeToNextStop?.[0]?.toStop; // First item is the next stop
        const referenceScheduledTimes = data?.[0]?.scheduledTime || [];
        const referenceScheduledIndex = referenceScheduledTimes.findIndex((time) => {
          const [hours, minutes] = time.split(':').map(Number);
          const timeMinutes = hours * 60 + minutes;
          return timeMinutes >= currentMinutes;
        });

        const matchingTimeToNextStop = timeToNextStop?.find(
          (timeItem) => timeItem.toStop === item.name
        );

        const isActive = timeToNextStop ? timeToNextStop.length > 0 : undefined;

        const { scheduledTime, nextTimes } = findClosestTimes(
          currentMinutes,
          item.scheduledTime || [],
          matchingTimeToNextStop?.isStopPassed || false,
          referenceScheduledIndex,
          isActive 
        );

        const activeTime = item.totalTime
          ? formatTime(Math.abs(item.totalTime))
          : scheduledTime;

        return (
          <div
            key={`${item.name}-${index}`}
            className={`item-container ${item.isPassed ? "inactive-stop" : ""}`}
            ref={item.isNextStop ? nextStopRef : null}
          >
            <div className="timeline-connector">
              <div
                className="circle"
                style={{
                  backgroundColor: item.isNextStop
                    ? COLORS.mossGreen
                    : "#3b3938",
                }}
              />
              {!lastItem && <div className="line short-line" />}
            </div>

            <div
              className={`item-content ${item.isPassed ? "passed-stop" : ""}`}
              style={{
                backgroundColor: item.isNextStop
                  ? COLORS.mossGreen
                  : "transparent",
                border: "1.5px solid",
                borderColor: COLORS.mossGreen,
              }}
              onClick={(event) => {
                event.preventDefault();
                handleNavigate(item.id);
              }}
            >
              <div className="text-container">
                {item.isNextStop ? (
                  <img
                    className="imgTimelineItem"
                    src="/images/img/busLinesAndBusStopDetailsLineLight.png"
                    alt=""
                  />
                ) : (
                  <img
                    className="imgTimelineItem"
                    src="/images/img/busLinesAndBusStopDetailsLine.png"
                    alt=""
                  />
                )}

                <div className="text-container-inner">
                  <h4
                    className="item-title"
                    style={{ color: item.isNextStop ? "white" : "#3b3938" }}
                  >
                    {item.name}
                  </h4>
                  {nextTimes.length > 0 ? (
                    <div className="next-times">
                      {item.totalTime !== undefined && isActive && (
                        <div
                          className="time-container"
                          style={{
                            backgroundColor: item.isNextStop
                              ? COLORS.cardBackgroundColorLight
                              : COLORS.mossGreen,
                          }}
                        >
                          <span
                            className="timelineTimeLeft"
                            style={{
                              color: item.isNextStop ? COLORS.mossGreen : "white",
                              fontWeight: item.isNextStop ? "bold" : "",
                            }}
                          >
                            {activeTime || ""}
                          </span>
                        </div>
                      )}
                      {/* Render next times */}
                      {nextTimes
                        .filter((time) => {
                          const timeDifference = calculateTimeDifference(time);
                          return timeDifference > 0;
                        })
                        .slice(0, 3)
                        .map((time, idx) => {
                          return (
                            <div
                              className="time-box"
                              key={idx}
                              style={{
                                backgroundColor: COLORS.timeBoxColorLight,
                              }}
                            >
                              <p className="m-0">
                                {time}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <span>Моментално нема автобус.</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;