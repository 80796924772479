import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BusLineDetails,
  BusLines,
  BusStopDetails,
  BusStops,
  HomePage2ActiveBus,
  MessagesAboutUs,
  OperatingHours,
} from "../../Types/Types";
import { LocationContext } from "../LocationContext/LocationContext";

interface AppContextType {
  busStops: BusStops[];
  getBusStopDetailsById: (id: string) => void;
  busStopDetails: BusStopDetails | null;
  resetBusStopDetails: () => void;
  busLines: BusLines[];
  fetchBusLineDetailsById: (id: string) => void;
  busLineDetails: BusLineDetails | null;
  resetBusLineDetails: () => void;
  fetchingOperatingHoursByLineID: (id: string) => void;
  operatingHoursForEachLine: OperatingHours | null;
  resetOperatinghouts: () => void;
  operatinhHoursModal: boolean;
  openOperatinghoursModal: () => void;
  closeOperatinghoursModal: () => void;
  fullScheduledTimeModal: boolean;
  openScheduledTimeModal: () => void;
  closeScheduledTimeModal: () => void;
  messagesAboutUs: MessagesAboutUs | undefined;
  handleSearchBar: () => void;
  showHideSearchBar: boolean;
  handleSearchValue: (text: string) => void;
  searchValue: string;
  resetSearchValue: () => void;
  activeBusHP: HomePage2ActiveBus[];
  onFormSubmit: (selectValue: string, searchValue: string) => void;
  handleSelectValue: (selectedValue: string) => void;
  selectValue: string;
  fetchActiveBuses: () => void;
  getAboutUsMessages: () => void;
  getBusLines: () => void;
  isNotFound: boolean;
  setIsNotFound: (value: boolean) => void;
}

export const AppContext = createContext<AppContextType>({
  busStops: [],
  getBusStopDetailsById: () => {},
  busStopDetails: null,
  resetBusStopDetails: () => {},
  busLines: [],
  fetchBusLineDetailsById: () => {},
  busLineDetails: null,
  resetBusLineDetails: () => {},
  fetchingOperatingHoursByLineID: () => {},
  operatingHoursForEachLine: null,
  resetOperatinghouts: () => {},
  operatinhHoursModal: false,
  openOperatinghoursModal: () => {},
  closeOperatinghoursModal: () => {},
  fullScheduledTimeModal: false,
  openScheduledTimeModal: () => {},
  closeScheduledTimeModal: () => {},
  messagesAboutUs: undefined,
  handleSearchBar: () => {},
  showHideSearchBar: false,
  handleSearchValue: () => {},
  searchValue: "",
  resetSearchValue: () => {},
  activeBusHP: [],
  onFormSubmit: (selectValue: string, searchValue: string) => {},
  handleSelectValue: (selectedValue: string) => {},
  selectValue: "",
  fetchActiveBuses: () => {},
  getAboutUsMessages: () => {},
  getBusLines: () => {},
  isNotFound: false,
  setIsNotFound: () => {},
});

interface Props {
  children: React.ReactNode;
}

const part1 = process.env.REACT_APP_1;
const part2 = process.env.REACT_APP_2;
const part3 = process.env.REACT_APP_3;
const part4 = process.env.REACT_APP_4;
const part5 = process.env.REACT_APP_5;
const part6 = process.env.REACT_APP_6;


const api = `${part2}${part3}${part4}${part5}${part6}`;


const AppContextConstructor: React.FC<Props> = ({ children }) => {
  const [busStops, setBusStops] = useState<BusStops[]>([]);
  const [busStopDetails, setBusStopDetails] = useState<BusStopDetails | null>(
    null
  );
  const [busLines, setBusLines] = useState<BusLines[]>([]);
  const [busLineDetails, setBusLineDetails] = useState<BusLineDetails | null>(
    null
  );
  const [operatingHoursForEachLine, setOperatingHoursForEachLine] =
    useState<OperatingHours | null>(null);
  const [operatinhHoursModal, setOperatinhHoursModal] =
    useState<boolean>(false);
  const [fullScheduledTimeModal, setFullScheduledTimeModal] =
    useState<boolean>(false);
  const [messagesAboutUs, setMessagesAboutUs] = useState<
    MessagesAboutUs | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectValue, setSelectValue] = useState<string>("");
  const [showHideSearchBar, setShowHideSearchBar] = useState<boolean>(false);
  const [activeBusHP, setActiveBusHP] = useState<HomePage2ActiveBus[]>([]);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const { location } = useContext(LocationContext);

  useEffect(() => {
    fetchActiveBuses();
  }, []);

  const fetchActiveBuses = async () => {
    try {
  //added from the new api
      const response = await fetch(`https://activebuses-hx67ea5zua-ew.a.run.app/activeBuses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      
  
      if (response.status === 404) {
        setActiveBusHP([]);
        setIsNotFound(true);
        return;
      }
  
      const data: HomePage2ActiveBus[] = await response.json();
      setActiveBusHP(data);
      setIsNotFound(false);
    } catch (error) {
      console.error("Error fetching active buses:", error);
      setActiveBusHP([]);
      setIsNotFound(false);
    }
  };

  useEffect(() => {

      //added from the new api
    const fetchBusStops = async () => {
      const lat = location?.latitude;
      const lon = location?.longitude;
  
      const params = new URLSearchParams();
  
      if (searchValue) params.append('name', searchValue);
      if (selectValue) params.append('neighbourhood', selectValue);
      if (lat && lon) {
        params.append('lat', lat.toString()); // Convert lat to string
        params.append('lon', lon.toString()); // Convert lon to string
      }
  
      const url = `https://getstops-hx67ea5zua-ew.a.run.app/search?${params.toString()}`;
  
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data: BusStops[] = await response.json();
        setBusStops(data);
      } catch (error) {
        console.error("Error fetching bus stops:", error);
      }
    };
  
    fetchBusStops();
  }, [location, searchValue, selectValue]);

  const getBusLines = useCallback(() => {
  //added from the new api
    fetch(`https://activebuses-hx67ea5zua-ew.a.run.app/activeBuses`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",   
      },
    })
      .then((res) => res.json())
      .then((data: BusLines[]) => {
        setBusLines(data);
      });
  }, []);

  const getAboutUsMessages = useCallback(() => {
    fetch(`https://europe-west1-citybusbitola.cloudfunctions.net/aboutUs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",   
      },
    })
      .then((res) => res.json())
      .then((data: MessagesAboutUs) => {
        setMessagesAboutUs(data);
      });
  }, []);

  const getBusStopDetailsById = useCallback(
    async (id: string) => {
      try {
        const res = await fetch(
  //added from the new api
          `https://getstopdetails-hx67ea5zua-ew.a.run.app/stop?stopId=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data: BusStopDetails = await res.json();

        if (res.status === 430) {
          setBusStopDetails(data);
        } else if (!res.ok) {
          throw new Error(
            `Network response was not ok: ${res.status} - ${res.statusText}`
          );
        } else {
          setBusStopDetails(data);
        }
      } catch (error) {
        console.error("Failed to fetch bus stop details:", error);
      }
    },
    [setBusStopDetails]
  );

  const fetchBusLineDetailsById = useCallback(
    async (id: string) => {
      try {
        setBusLineDetails(null);
  //added from the new api
        const res = await fetch(
          `https://linedetails-hx67ea5zua-ew.a.run.app/lineDetails?lineParam=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!res.ok) {
          throw new Error(`Network response was not ok: ${res.status} - ${res.statusText}`);
        }
  
        const contentType = res.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const data = await res.json();
    
          if (data && Object.keys(data).length > 0) {
            setBusLineDetails(data);
          } else {
            console.error("Received empty or invalid data.");
          }
        } else {
          console.error("Expected JSON but received something else.");
        }
      } catch (error) {
        console.error("Failed to fetch line details:", error);
      }
    },
    [setBusLineDetails]
  );
  

  const fetchingOperatingHoursByLineID = async (id: string) => {
    //new api
    fetch(`https://getoperatinghours-hx67ea5zua-ew.a.run.app/bus/${id}`)
      .then((res) => res.json())
      .then((data: OperatingHours) => {
        setOperatingHoursForEachLine(data);
      })
      .catch((error) => {
        console.error("Failed to fetch operating hours:", error);
      });
  };

  const resetBusStopDetails = () => {
    setBusStopDetails(null);
  };

  const resetBusLineDetails = () => {
    setBusStopDetails(null);
  };

  const resetOperatinghouts = () => {
    setOperatingHoursForEachLine(null);
  };

  const openOperatinghoursModal = () => {
    setOperatinhHoursModal(true);
  };

  const closeOperatinghoursModal = () => {
    setOperatinhHoursModal(false);
  };

  const openScheduledTimeModal = () => {
    setFullScheduledTimeModal(true);
  };

  const closeScheduledTimeModal = () => {
    setFullScheduledTimeModal(false);
  };

  const handleSearchBar = () => {
    setSearchValue("");
    setShowHideSearchBar(!showHideSearchBar);
  };

  const handleSearchValue = (text: string) => {
    setSearchValue(text);
  };

  const resetSearchValue = () => {
    setSearchValue("");
    setShowHideSearchBar(false);
  };

  const onFormSubmit = (selectValue: string, searchValue: string) => {
    setSelectValue(selectValue);
    setSearchValue(searchValue);
  };

  const handleSelectValue = (selectedValue: string) => {
    setSelectValue(selectedValue);
  };

  return (
    <AppContext.Provider
      value={{
        busStops,
        getBusStopDetailsById,
        busStopDetails,
        resetBusStopDetails,
        busLines,
        fetchBusLineDetailsById,
        busLineDetails,
        resetBusLineDetails,
        fetchingOperatingHoursByLineID,
        operatingHoursForEachLine,
        resetOperatinghouts,
        openOperatinghoursModal,
        closeOperatinghoursModal,
        operatinhHoursModal,
        fullScheduledTimeModal,
        openScheduledTimeModal,
        closeScheduledTimeModal,
        messagesAboutUs,
        handleSearchBar,
        showHideSearchBar,
        handleSearchValue,
        searchValue,
        resetSearchValue,
        activeBusHP,
        onFormSubmit,
        handleSelectValue,
        selectValue,
        fetchActiveBuses,
        getAboutUsMessages,
        getBusLines,
        isNotFound,
        setIsNotFound,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextConstructor;
function setIsNotFound(arg0: boolean) {
  throw new Error("Function not implemented.");
}

