import React, { useContext } from "react";
import { BusStops } from "../../Types/Types";
import { COLORS, MARGINS } from "../../Styles/GlobalStyles";
import { useNavigate } from "react-router-dom";
import "../../Styles/BusStopCardStyle/BusStopCard.css";
import {
  formatDistanceFromMToKm,
  scrollToTop,
} from "../../Utils/UtilityFunctions";
import { AppContext } from "../../Context/AppContext/AppContext";

const BusStopCard: React.FC<BusStops> = ({
  id,
  name,
  location,
  lines,
  direction,
  calculatedDistance,
}) => {
  const { resetSearchValue } = useContext(AppContext);
  const navigate = useNavigate();

  const navigateTo = (id: string) => {
    navigate(`/stop/${id}`);
    resetSearchValue();
  };

  const handleNavigateGoogleMaps = (lat: number, lon: number) => {
    const url = `https://www.google.com/maps/place/${lat},${lon}`;
    window.open(url, "_blank");
  };

  return (
    <div
      className="d-flex aligh-items-center busStopContainer"
      style={{
        backgroundColor: "transparent",
        borderColor: COLORS.mossGreen,
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        if (id) {
          navigateTo(id);
        }
        scrollToTop();
      }}
    >
      <div className="d-flex align-items-center">
        <img
          className="imgBusStopCard"
          src="/images/img/busStationHome.png"
          alt=""
          style={{
            marginRight: MARGINS.margin10,
          }}
        />
      </div>
      <div>
        <p className="stopTitle">
          {name}{" "}
          {direction && (
            <span
              style={{ fontSize: "14px", fontWeight: "400" }}
              className="text-muted"
            >
              ({direction})
            </span>
          )}
        </p>

        {calculatedDistance && (
          <p className="locationCalculation mb-1" style={{ color: "gray" }}>
            Оваа постојка е на {formatDistanceFromMToKm(calculatedDistance)} од
            вас.
          </p>
        )}

        {lines && lines.length > 0 ? (
          <div className="lineContainer">
            {lines.map((line, index) => (
              <p
                key={`${line.line}-${index}`}
                className="titleNumbers"
                style={{ backgroundColor: COLORS.mossGreen }}
              >
                {line.line}
              </p>
            ))}
          </div>
        ) : (
          <>
            <div>
              <p className="text-danger m-0">Нема линии на оваа постојка.</p>
            </div>
          </>
        )}
      </div>
      <div className="d-flex align-items-center ml-auto">
        {location && (
          <>
            <button
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.stopPropagation();
                handleNavigateGoogleMaps(location[0], location[1]);
              }}
              className="navigateToGoogleMapsButton"
            >
              <img
                src="/images/img/busStopLocation.png"
                alt=""
                className="navigateToGoogleMapsImage"
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default BusStopCard;
